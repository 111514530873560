<template>
<Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
<WhatsAppButton></WhatsAppButton>
  <div class="privacy-info-sec">
    <div class="container">
      <div class="">
          <div class="col-md-12 mb-5 about">
            <template v-for="(data,index) in fetchCompanyPolicyData" :key="index">
               <template v-if="data.stName=='about'  &&  this.lang!='ar'">
                 <div class="mt-5"  v-html="data.stValue"></div>
                
               </template>
                <template v-else-if="data.stName=='aboutAr' &&  this.lang=='ar'">
                        <div class="mt-5"  v-html="data.stValue"></div>
                </template>

            </template>
                 
              
          </div>
         
      </div>
    </div>
    </div>
    
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import helper from "@/helper/helper";
import WhatsAppButton from "@/components/WhatsAppButton";
import {mapGetters} from 'vuex';
export default {
     name: "About",
     props: [ "changed_url",'translationloaded',
      'translate'],
      data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },
        {
          path: "/about",
          name: "aboutUs",
        },
      ],
       lang:'',
      
     
    };
  },
  components: {
    Breadcrumb,
    WhatsAppButton
   
  },
  mounted(){
       helper.backtoTop();
       this.lang = localStorage.getItem('lang');
  },
  computed:{
    ...mapGetters(['fetchCompanyPolicyData']),
  }
}
</script>
<style >
   .about h3{
        text-align: center;
        width:100%;
        margin-top: 30px!important;
    }
    .about p {
    font-size: 1.6rem;
    line-height: 1.8em;
    color: #666;
    }
    .about h4{
        margin-top: 20px;
    }
    .about .privacy_ul{
        list-style-type: disc;
    }
    .privacy_ul li{
        margin-left: 30px;
    }
    .privacy_ol{
        list-style-type: lower-roman;
    }
    .privacy_ol li{
         margin-left: 30px;
    }

</style>